import React from 'react'
import TitleDescription from '../../misc/TitleDescription/TitleDescription'
import { CateringList } from '../CateringMenu/CateringList'
import * as styles from './CateringContact.module.scss'

const CateringContact = ({ cart }) => (
  <div className={styles.container} id="request">
    <TitleDescription
      descriptionParagraphs={[
        'Please notice us at least two days in advance. Our minimum order is 60€.',
        'Once you send your request, you will receive an email from our team within 24 hours. We will discuss with you regarding dietery requirements, topping options, and order quantity.',
        'You can pay at the restaurant when picking up your order. For delivery, please ask for a quote. Invoicing is also available for a 5€ fee.',
      ]}
      title="Send a request!"
    />
    <form
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      id="contact"
      method="POST"
      name="cateringContact"
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input name="form-name" readOnly type="hidden" value="cateringContact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </p>
      <p hidden>
        <input
          name="cart"
          readOnly
          value={JSON.stringify(cart.map(({ idx }) => CateringList[idx].title))}
        />
      </p>
      <p className={styles.inputWrapper}>
        <label className={styles.label}>Full Name</label>
        <br />
        <input className={styles.input} name="name" required type="text" />
      </p>
      <p className={styles.inputWrapper}>
        <label className={styles.label}>Phone number</label>
        <br />
        <input className={styles.input} name="phone" required type="tel" />
      </p>
      <p className={styles.inputWrapper}>
        <label className={styles.label}>Email</label>
        <br />
        <input className={styles.input} name="email" required type="email" />
      </p>

      <p className={styles.inputWrapper}>
        <label className={styles.label}>Catering Date</label>
        <br />
        <input className={styles.input} name="date" type="datetime-local" />
      </p>
      <p className={styles.inputWrapper}>
        <label className={styles.label}>
          Message to us:
          <br />
          <textarea className={styles.input} name="message" />
          <br />
          <i>
            hint: you can maybe tell us how many people are coming, so we can
            provide some recommendations!
          </i>
        </label>
      </p>
      {cart.length > 0 && (
        <>
          <p style={{ lineHeight: '1.2em' }}>
            You picked some favourites (not final order):
          </p>
          {cart.map(({ idx }) => (
            <p className={styles.favourite} key={idx}>
              - {CateringList[idx].title}
            </p>
          ))}
        </>
      )}

      <p style={{ marginTop: 30 }}>
        <button className={styles.button} type="submit">
          Send
        </button>
      </p>
    </form>
  </div>
)

export default CateringContact
