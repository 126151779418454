export const CateringList = [
  {
    title: 'Mini Summer Rolls',
    description: [
      '24 mixed mini summer rolls',
      'Topping: smoked salmon, five spices chicken, avocado (Vg), nigiri tofu (Vg)',
      'Dressing sauce: sesame, green chili',
    ],
    imageId: 'cateringrolls',
    price: 60,
  },
  {
    title: 'Coolest Bánh Mì On The Block',
    description: [
      '08 mixed half-Bánh Mì Viet style baguettes',
      'Vegetarian option available!',
    ],
    imageId: 'cateringbmi',
    price: 35,
  },
  {
    title: 'Noodle Around',
    description: [
      '8 mini portions',
      'Warm filling Viet noodles with fresh greens, homemade pickles, peanuts, herbs',
      'Vegan option available!',
    ],
    imageId: 'cateringnoodle',
    price: 60,
  },
  {
    title: 'Be My Poke',
    description: [
      '8 mini portions',
      'Rice, avocado, wakame, veggies, herbs and chili mayo',
    ],
    imageId: 'cateringpoke',
    price: 60,
    options: [
      {
        option: 'Shrimp or tofu (Vg)',
        price: 60,
      },
      {
        option: 'Fresh salmon',
        price: 70,
      },
    ],
  },
  {
    title: 'Flying Shrimp',
    description: [
      '15 shrimp skewers baked with spicy lemongrass sauce',
      'Green-chili dipping sauce',
    ],
    imageId: 'cateringflyingshrimp',
    price: 35,
  },
  {
    title: 'Chika Chika Wings',
    description: [
      '30 tasty spicy chicken wings glazed with fish sauce and spices',
    ],
    imageId: 'cateringwings',
    price: 25,
  },
  {
    title: 'Torpedo Shrimp',
    description: ['15 breaded torpedo shrimp sticks'],
    imageId: 'cateringshrimp',
    price: 25,
  },
  {
    title: 'Dumplings Darling',
    description: [
      '20 mixed filling dumplings, coriander, fried onion',
      'Vegan option available!',
    ],
    imageId: 'cateringdumpling',
    price: 25,
  },
  {
    title: "Charlie Don't Surf Salad",
    description: ['Special salad white cabbage, carrot, red onion, herbs (Vg)'],
    imageId: 'cateringcharlie',
    price: 20,
  },
  {
    title: 'Fresh Vermicelli Salad',
    description: ['Fresh and light salad, vermiceli, salad, herbs (Vg)'],
    imageId: 'cateringsalad',
    price: 20,
  },
  {
    title: 'Saigon Hipster',
    description: ['Spicy edamame beans (Vg)'],
    imageId: 'cateringhipster',
    price: 20,
  },
  {
    title: 'Cauliflower Popcorn',
    description: ['Crunchy roasted cauliflower (Vg)'],
    imageId: 'cateringcauli',
    price: 20,
  },
]
