// extracted by mini-css-extract-plugin
export var buttonContainer = "Catering-module--buttonContainer--AbiF3";
export var cateringIntro = "Catering-module--cateringIntro--Tx4gV";
export var cateringParagraph = "Catering-module--cateringParagraph---G8IE";
export var cateringTitle = "Catering-module--cateringTitle--xf3eR";
export var center = "Catering-module--center--zsoRa";
export var container = "Catering-module--container--uM07M";
export var coverImage = "Catering-module--coverImage--FZpiE";
export var image = "Catering-module--image--sHU8l";
export var introParallax = "Catering-module--introParallax--zh4nd";
export var questions = "Catering-module--questions--yJ+ck";
export var shape = "Catering-module--shape--MNhPZ";
export var titleWrapper = "Catering-module--titleWrapper--oe5TQ";