import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import Footer from "../../components/Footer/Footer"
import Layout from "../../components/Layout/Layout"
import Catering from "../../components/catering/Catering/Catering"
import NavbarContainer from "../../components/misc/NavbarContainer/NavbarContainer"

const CateringPage = () => {
  return (
    <ParallaxProvider>
      <Layout
        title="Be My Guest | Vietnamese Restaurant | Authentic Cuisine"
        description="Be My Guest – Vietnamese Restaurant in Jätkäsaari, Helsinki offers a
deeper dive into AUTHENTIC Vietnamese food culture. EXTRAORDINARY Pho &amp;
Summer Rolls"
      >
        <NavbarContainer dark />
        <Catering />
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default CateringPage
