// extracted by mini-css-extract-plugin
export var container = "CateringMenu-module--container--ID9KO";
export var content = "CateringMenu-module--content--sVKdu";
export var favBtn = "CateringMenu-module--favBtn--AINr6";
export var favSelected = "CateringMenu-module--favSelected--Flws5";
export var image = "CateringMenu-module--image--gD-CS";
export var imageContainer = "CateringMenu-module--imageContainer--Iv2K3";
export var itemBox = "CateringMenu-module--itemBox--ByzGj";
export var itemContainer = "CateringMenu-module--itemContainer--+moMM";
export var itemDescription = "CateringMenu-module--itemDescription--fUnMf";
export var itemOption = "CateringMenu-module--itemOption--r4siw";
export var itemTitle = "CateringMenu-module--itemTitle--IhPXr";
export var price = "CateringMenu-module--price--dp-NW";
export var shape = "CateringMenu-module--shape--CUUQp";
export var titleContainer = "CateringMenu-module--titleContainer--lY2WM";