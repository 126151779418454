import classnames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import { CateringList } from './CateringList'

import * as styles from './CateringMenu.module.scss'

const cx = classnames.bind(styles)

const CateringMenuItem = ({
  img,
  title,
  description,
  isSelected,
  onSelect,
  price,
  options,
}) => (
  <>
    <div className={styles.imageContainer}>
      <GatsbyImage image={img} className={styles.image} />
    </div>
    <div className={styles.content}>
      <div className={styles.titleContainer}>
        <h2 className={styles.itemTitle}>{title}</h2>
        <button
          className={cx([styles.favBtn, isSelected && styles.favSelected])}
          onClick={onSelect}
        >
          {isSelected ? 'added' : 'add to form'}
        </button>
      </div>
      <p className={styles.price}>{price}€</p>
      {options &&
        options.map(({ option, price }) => (
          <p className={styles.itemOption} key={option}>
            {option}: {price}€
          </p>
        ))}
      {description.map((text) => (
        <p key={text} className={styles.itemDescription}>
          {text}
        </p>
      ))}
    </div>
  </>
)
const CateringMenu = ({ cart, onUpdateCart }) => {
  const data = useStaticQuery(graphql`
    {
      cateringrolls: file(relativePath: { eq: "catering-rolls.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringbmi: file(relativePath: { eq: "catering-bmi.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringnoodle: file(relativePath: { eq: "catering-noodle.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringpoke: file(relativePath: { eq: "catering-be-my-poke.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringwings: file(relativePath: { eq: "catering-wings.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringshrimp: file(relativePath: { eq: "catering-shrimp.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringdumpling: file(relativePath: { eq: "catering-dumpling.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringcharlie: file(relativePath: { eq: "catering-charlie.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringsalad: file(relativePath: { eq: "catering-salad.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringhipster: file(relativePath: { eq: "catering-hipster.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringcauli: file(relativePath: { eq: "catering-cauli.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      cateringflyingshrimp: file(
        relativePath: { eq: "catering-flying-shrimp.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60)
        }
      }
      aboutParallax: file(relativePath: { eq: "about_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 250, quality: 30)
        }
      }
      menuParallax: file(relativePath: { eq: "menu_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 580, quality: 30)
        }
      }
    }
  `)

  return (
    <div className={styles.container}>
      {CateringList.map((item, idx) => {
        const random1 = idx === 4 || idx === 10
        const random2 = idx === 0 || idx === 8
        const isSelected = cart.find((c) => c.idx === idx)

        return (
          <div key={`catering-${idx}`} className={styles.itemContainer}>
            {random1 && (
              <Parallax
                translateX={['120px', '-20px']}
                translateY={['50px', '-80px']}
                tagOuter="figure"
              >
                <GatsbyImage
                  image={data.aboutParallax.childImageSharp.gatsbyImageData}
                  className={styles.shape}
                />
              </Parallax>
            )}
            {random2 && (
              <Parallax
                translateX={['-40px', '40px']}
                translateY={['-50px', '50px']}
                tagOuter="figure"
              >
                <GatsbyImage
                  image={data.menuParallax.childImageSharp.gatsbyImageData}
                  className={styles.shape}
                />
              </Parallax>
            )}

            <CateringMenuItem
              title={item.title}
              img={data[item.imageId].childImageSharp.gatsbyImageData}
              description={item.description}
              botranslateX={idx + 1}
              price={item.price}
              isSelected={isSelected}
              options={item.options}
              onSelect={() => {
                if (isSelected) {
                  onUpdateCart(cart.filter((c) => c.idx !== idx))
                } else {
                  onUpdateCart([...cart, { idx }])
                }
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default CateringMenu
