import classnames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import * as styles from './ButtonLink.module.scss'

const cx = classnames.bind(styles)

const ButtonLink = ({ linkTo, text, customClass, filled }) => {
  return (
    <Link to={linkTo} className={styles.link}>
      <div
        className={cx([styles.linkBtn, customClass, filled && styles.filled])}
      >
        {text}
      </div>
    </Link>
  )
}

export default ButtonLink
