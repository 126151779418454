import React from 'react'
import Gallery from '../../Gallery/Gallery'
import TitleDescription from '../../misc/TitleDescription/TitleDescription'

const CateringGallery = () => {
  return (
    <TitleDescription
      customStyles={{ paddingBottom: 100 }}
      title="Testimonials"
      description="But don't take our words for it. Let's hear from other customers as well, shall we?"
    >
      <Gallery />
    </TitleDescription>
  )
}

export default CateringGallery
