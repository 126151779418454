import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import ButtonLink from '../../misc/ButtonLink/ButtonLink'
import ContentLayout from '../../misc/ContentLayout/ContentLayout'
import TitleDescription from '../../misc/TitleDescription/TitleDescription'
import CateringContact from '../CateringContact/CateringContact'
import CateringGallery from '../CateringGallery/CateringGallery'
import CateringMenu from '../CateringMenu/CateringMenu'
import * as styles from './Catering.module.scss'

const Catering = () => {
  const [cart, setCart] = React.useState([])
  const data = useStaticQuery(graphql`
    {
      introParallax: file(relativePath: { eq: "intro_parallax.png" }) {
        childImageSharp {
          gatsbyImageData(width: 900, quality: 70, layout: CONSTRAINED)
        }
      }
      picture: file(relativePath: { eq: "catering-1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH)
        }
      }
      picture2: file(relativePath: { eq: "catering-2.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60, layout: CONSTRAINED)
        }
      }
      picture3: file(relativePath: { eq: "catering-3.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 60, layout: CONSTRAINED)
        }
      }
      shape: file(relativePath: { eq: "intro_shape.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 30, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <div className={styles.container}>
      <GatsbyImage
        image={data.picture.childImageSharp.gatsbyImageData}
        className={styles.coverImage}
      />
      <div className={styles.buttonContainer}>
        <ButtonLink linkTo="/catering#menu" text="Catering Menu" filled />
        <ButtonLink linkTo="/catering#request" text="Send Request" filled />
      </div>
      <div className={styles.center}>
        <GatsbyImage
          image={data.shape.childImageSharp.gatsbyImageData}
          className={styles.shape}
        />
      </div>

      <div className={styles.cateringIntro}>
        <div className={styles.titleWrapper}>
          <h2 style={{ fontSize: 25 }}>Gathering?</h2>
          <strong>
            <h2 className={styles.cateringTitle}>Catering.</h2>
          </strong>
        </div>
        <p className={styles.cateringParagraph}>
          <strong>
            Gathering friends for a special do? Home birthday party?
          </strong>
        </p>
        <p className={styles.cateringParagraph}>
          Imagine fresh rolls in rainbow colors. Crunchy, tasty Vietnamese
          filled baquette (Bánh Mì). Lush Hanoi style noodles. Finger licking,
          with a kick, wings glazed with fish sauce.
        </p>
        <p className={styles.cateringParagraph}>
          Fresh, healthy, delicious, and affordable. Be the best host they ever
          met.
        </p>
        <p className={styles.cateringParagraph}>
          {"Give us a buzz, we'll sort you out!"}
        </p>
      </div>
      <Parallax translateX={['100px', '-80px']} translateY={['100px', '-80px']}>
        <div className={styles.center}>
          <GatsbyImage
            image={data.picture3.childImageSharp.gatsbyImageData}
            className={styles.image}
          />
        </div>
      </Parallax>
      <Parallax translateX={['-100px', '80px']} translateY={['-30px', '50px']}>
        <GatsbyImage
          image={data.introParallax.childImageSharp.gatsbyImageData}
          className={styles.introParallax}
        />
      </Parallax>

      <div id="menu">
        <ContentLayout customStyle={{ paddingTop: 100 }}>
          {/* <CateringGallery /> */}
          <TitleDescription title="Catering Menu" />
          <CateringMenu
            cart={cart}
            onUpdateCart={(newCart) => {
              setCart(newCart)
            }}
          />
          <CateringContact cart={cart} />
        </ContentLayout>
      </div>
    </div>
  )
}

export default Catering
